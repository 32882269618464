import { Stack } from "@mui/material";
import React from "react";
import { IconArrowTop } from "../../theme/Icons";

function Scrolltotop({scrollFunction}) {
    const handleFunction = () => {
      scrollFunction();
    };
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "50px",
        height: "50px",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        bgcolor: "#CD2028",
        color: "white",
          borderRadius: "50%",
        cursor:"pointer"
          }}
          onClick={handleFunction}
    >
      <IconArrowTop font="30px" />
    </Stack>
  );
}

export default Scrolltotop;
