import { APIURL } from "../config";
import axios from "axios";

// console.log(APIURL);

export const HeaderData = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/Json",
  //   Authorization: `Bearer ${token}`,
};

export const getCountries = async () => {
  const response = await axios.get(`${APIURL}admin/countries/get`, {
    headers: HeaderData,
  });

  return response;
};

export const getGlobalFootPrints = async () => {
  const response = await axios.get(`${APIURL}get/globalbranches`, {
    headers: HeaderData,
  });

  return response;
};

export const raiseEnquiry = async (data) => {
  const response = await axios.post(
    `${APIURL}enquiry`,
    JSON.stringify({
      fullname: data.fullname,
      company: data.company,
      email: data.wemail,
      country: data.country,
      mobile: data.mobile,
      message: data.message,
      tc: data.tc,
      domain: data.domain,
      page: data.page,
      industry:data.industry
    }),
    {
      headers: HeaderData,
    }
  );

  return response;
};

export const ViewCount = async (data) => {
  const response = await axios.post(
    `${APIURL}visitors`,
    JSON.stringify({
      browsername: data.browsername,
      browserversion: data.browserversion,
      deviceSizeType: data.deviceSizeType,
      deviceVP: data.deviceVP,
      visitedPage: data.page,
      domain: "relevantsolution.com.my",
    }),
    {
      headers: HeaderData,
    }
  );
  // console.log(data)
  return response;
};

export const getlogos = async () => {
  const response = await axios.get(`${APIURL}get/logos`, {
    headers: HeaderData,
  });

  return response;
};


