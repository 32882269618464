import { Stack, useMediaQuery } from "@mui/material";
import React from "react";
import Carousel from "../components/resuable/Carousel";
import { InforCustomizaton } from "../mock/Infor";
import MainHeading from "../components/resuable/MainHeading";

function InforCustomizationData() {
  const Mobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: Mobile || isTab ? "auto" : "inherit",
        width: Mobile || isTab ? "90%" : "75%",
        p: Mobile || isTab ? "10px" : "20px",
      }}
      spacing={2}
    >
      <MainHeading Heading="Infor Customizations" />

      <Carousel carouselImages={InforCustomizaton} />
    </Stack>
  );
}

export default InforCustomizationData;
