import React, { Fragment, useEffect, useRef, useState } from "react";
import Page from "../components/common/Page";
import BannerComponent from "../components/resuable/BannerComponent";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  isTabs,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
} from "@mui/material";
import MainHeading from "../components/resuable/MainHeading";
import { styled } from "@mui/material/styles";
import { IconArrowRight, IconRightArrow } from "../theme/Icons";
import Scrolltotop from "../components/resuable/Scrolltotop";

import Carousel from "../components/resuable/Carousel";
import GridsData from "../sections/GridsData";
import InforUpgradesData from "../sections/InforUpgradesData";
import InforMigrationData from "../sections/InforMigrationData";
import InforTraining from "../sections/InforTraining";
import InforCustomizationData from "../sections/InforCustomizationData";
import InforMCData from "../sections/InforMCData";
import InforRecruitingData from "../sections/InforRecruitingData";
import Contactus from "./Contactus";
import { getlogos } from "../api/Main";
import CustomerCarousel from "../components/resuable/CustomerCarousel.js";

export const SectionContainer = styled("section")(({ theme, bcolor }) => ({
  height: "100vh",
  width: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  backgroundColor: bcolor,
  // position: "absolute",
  // top:"75px",
  // marginTop: "105px",
  // border: "1px solid gray",
  // padding: "10px",
  // marginBottom: "10px",

  [theme.breakpoints.between("xs", "md")]: {
    //  mobile
    height: "auto",
    width: "100%",
    // marginTop: "10px",
  },

  [theme.breakpoints.between("sm", "md")]: {
    // isTab
    // height: "400px",
  },

  [theme.breakpoints.between("md", "lg")]: {
    // Desktop
  },

  [theme.breakpoints.up("xl")]: {
    // Desktop
    // width: "100%",
    // height: "600px",
  },
}));

function Home() {
  const [hover, setHover] = useState(false);

  const [menuid, setMenuid] = useState(0);
  const [scrollid, setscrollid] = useState(0);

  const handleMouseEnters = (id) => {
    setMenuid(id);
    setHover(true);
  };

  const handleMouseLeaves = () => {
    setHover(false);
  };

  const handleNavigate = (id) => {
    setscrollid(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const Mobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 const [logos, setLogos] = useState([]);
 const isMounted = useRef(false);

 useEffect(() => {
   // Set the ref to true when the component mounts
   isMounted.current = true;

   getlogos()
     .then((res) => {
       console.log(res);
       const data = res.data.response;
       const customdomain = "relevantsolution.com.my";
       const filtereddata = data.filter((item) =>
         item.domain.includes(customdomain)
       );
       if (isMounted.current) {
         setLogos(filtereddata);
       }
       console.log(filtereddata);
     })
     .catch((err) => {
       console.log(err);
     });

   return () => {
     // Clean up the ref when the component unmounts
     isMounted.current = false;
   };
 }, []);

 

  // const logodata = [
  //   {
  //     dept: "Infor",
  //     id: 79,
  //     logoname: "Flex",
  //     logotype: "Customer",
  //   },
  //   {
  //     dept: "Infor",
  //     id: 79,
  //     logoname: "Flex",
  //     logotype: "Customer",
  //   },
  //   {
  //     dept: "Infor",
  //     id: 79,
  //     logoname: "Flex",
  //     logotype: "Customer",
  //   },
  // ];

  return (
    <Fragment>
      <Page
        description="Relevant Business Solutions, offers a wide array of Infor Services with Staffing."
        pagename="Infor Page"
      />
      <BannerComponent
        mainheight="600px"
        textdispaly={
          <Stack
            direction="column"
            alignItems="right"
            justifyContent="center"
            sx={{ height: "500px", width: "90%" }}
            spacing="30px"
          >
            <Typography
              variant={Mobile || isTab ? "h4" : "h2"}
              sx={{
                fontWeight: "bold",
                color: "#D6000D",
                width: "auto",
                textAlign: "right",
              }}
            >
              Infor <br /> Consulting <br /> Service
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="right"
              sx={{ width: "100%" }}
            >
              <Button
                variant="contained"
                sx={{ width: "120px", alignSelf: "right" }}
                onClick={() => handleNavigate("contactus")}
              >
                Contact us
              </Button>
            </Stack>
          </Stack>
        }
      />

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", position: "relative" }}
      >
        <SectionContainer bcolor="#F1F7FD">
          <InforRecruitingData />
        </SectionContainer>
        <SectionContainer id={6} bcolor="#Ffffff">
          <InforTraining />
        </SectionContainer>
        <SectionContainer id={4} bcolor="#F1F7FD">
          <InforMCData />
        </SectionContainer>
        <SectionContainer id={3} bcolor="#FFFFFF">
          <InforCustomizationData />
        </SectionContainer>
        <SectionContainer id={2} bcolor="#F1F7FD">
          <InforMigrationData />
        </SectionContainer>
        <SectionContainer id={1} bcolor="#FFFFFF">
          <InforUpgradesData />
        </SectionContainer>
        <GridsData
          Hover={hover}
          Menuid={menuid}
          Navigate={handleNavigate}
          MouseEnters={handleMouseEnters}
          MouseLeaves={handleMouseLeaves}
        />
        {/* {logos ? (
          <CustomerCarousel
            carouselname="Our Customers"
            carouselImages={logos}
          />
        ) : null} */}

        <SectionContainer id="contactus" bcolor="#F1F7FD">
          <Contactus />
        </SectionContainer>
        <Scrolltotop scrollFunction={() => handleNavigate("grids")} />
      </Stack>
    </Fragment>
  );
}

export default Home;
