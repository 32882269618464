import { Button, Stack, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Relevant from "../../assets/logo.png";

// import styled from 'styled-components';

const MainContainer = styled("header")(
  ({ theme, hover, toggle, visibility, nobanne, scroll }) => ({
    height: "75px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 99999,
    transition: "transform 1s ease",
    // border: "1px solid lightgray",
    backdropFilter: !visibility && !scroll ? "blur(0px)" : "blur(10px)",
    background: !visibility && !scroll ? "rgba(195, 255, 255, 0.1)" : null,
    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    // border:
    //   hover || nobanner
    //     ? "1px solid rgba(255, 255, 255, 0.3)"
    //     : "1px solid lightgray",
    transform: visibility ? "translateY(-100%)" : "translateY(0)",

    [theme.breakpoints.between("xs", "md")]: {
      // Mobile
      position: "relative",
      background: "rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(10px)",
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
      // border: "1px solid rgba(255, 255, 255, 0.3)",
    },

    [theme.breakpoints.between("md", "lg")]: {
      // Desktop
    },

    [theme.breakpoints.up("xl")]: {},
  })
);

function Navbar() {
  // ------------------------------------------------------------- Media Quires

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const isDesktop = useMediaQuery((theme) =>
    theme.breakpoints.between("md", "lg")
  );

  const isLargeScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("lg", "xl")
  );

  const isXLarge = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  // ------------------------------------------------------------- States
  const [scrolling, setScrolling] = useState(false);
  const [scrolling1, setScrolling1] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setScrolling1(true);
      } else {
        // Scrolling up
        setScrolling1(false);
      }
      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);
  return (
    <MainContainer
      visibility={isMobile || isTab ? null : scrolling1}
      scroll={isMobile || isTab ? null : scrolling}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        component={Link}
        to="/home"
        sx={{ p: "15px", width: "auto", height: "60px" }}
      >
        <img
          src={Relevant}
          alt="Relevant Solutions Malaysia + Kapil tech"
          style={{ maxHeight: "100%", maxWidth: "100%" }}
        />
      </Stack>
    </MainContainer>
  );
}

export default Navbar;
