const Main_Server = process.env.REACT_APP_MAIN_SERVER_URL;

const Test_Server = process.env.REACT_APP_TEST_SERVER_URL;

const Local_Server = process.env.REACT_APP_LOCAL_HOST_URL;

// export const APIURL = URL1

export const APIURL = Test_Server;

// export const APIURL = Local_Server;
