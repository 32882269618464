import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import MainHeading from "../components/resuable/MainHeading";
import { Link } from "react-router-dom";
import Contactform from "../components/forms/Contactform";
import { getGlobalFootPrints } from "../api/Main";
import { IconEmail, IconMobile } from "../theme/Icons";

function Contactus() {
  const Mobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const isTab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const [footprints, setfooprints] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("Malaysia");

  useEffect(() => {
    getGlobalFootPrints()
      .then((res) => {
        console.log(res);
        const data = res.data.response;
        const actualDomain = "relevantsolution.com.my";
        const filteredList = data.filter((item) =>
          item.showin.includes(actualDomain)
        );
        setfooprints(filteredList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        height: Mobile || isTab ? "auto" : "inherit",
        width: Mobile || isTab ? "100%" : "100%",
        // p: "20px",
      }}
      spacing={2}
    >
      <Grid
        container
        // columnGap={2}
        // rowGap={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: Mobile || isTab ? "auto" : "100%",
          width: "100%",
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            // border: "1px solid lightgray",
            p: "40px",
            height: Mobile || isTab ? "auto" : "100%",
            bgcolor: "#C3FFFF",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <MainHeading Heading="Connect with us !" />

          <Stack
            direction={Mobile || isTab ? "column" : "row"}
            alignItems="center"
            justifyContent="center"
            sx={{ width: "100%" }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%", py: "15px" }}
              spacing={1}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "black" }}
              >
                Email :
              </Typography>
              <Link
                to="mailto:info@relevantsolution.com.my"
                style={{ textDecoration: "none", color: "black" }}
              >
                info@relevantsolution.com.my
              </Link>
            </Stack>

            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%", py: "15px" }}
              spacing={1}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "black" }}
              >
                Mobile:
              </Typography>
              <Link
                to="tel:+60 11 186 57058"
                style={{ textDecoration: "none", color: "black" }}
              >
                +60 11 186 57058
              </Link>
            </Stack>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "100%", py: "15px" }}
              spacing={1}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "black" }}
              >
                WhatsApp:
              </Typography>

              <Link
                target="blank"
                to="https://wa.me/601118657058"
                style={{ textDecoration: "none", color: "black" }}
              >
                +60 11 186 57058
              </Link>
            </Stack>
          </Stack>

          <Stack width="100%" sx={{py:"15px"}}>
            <hr />
          </Stack>
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{
              width: "100%",
              // border: "1px solid blue",
              height: Mobile || isTab ? "auto" : "300px",
            }}
          >
            <Stack
              direction={Mobile || isTab ? "column" : "row"}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={{ width: "100%", py: "5px" }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", px: "5px", color: "#CD2028" }}
              >
                Our Global Branches
              </Typography>

              <TextField
                select
                label="Countries"
                size="small"
                sx={{ width: Mobile || isTab ? "100%" : "50%" }}
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                {/* Render menu items for countries */}
                {footprints
                  .map((item) => item.country) // Extract unique country names
                  .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
                  .map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Stack
              direction={Mobile || isTab ? "column" : " row"}
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{
                width: "100%",
                // border: "1px solid blue",
                height: Mobile || isTab ? "auto" : "300px",
                py: "10px",
                px: "5px",
              }}
            >
              {/* Filter and display addresses based on selectedCountry */}
              {footprints
                .filter((item) => item.country === selectedCountry)
                .map((item) => (
                  <Fragment key={item.id}>
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="start"
                      spacing={1}
                    >
                      {item.careof ? (
                        <Typography
                          variant={Mobile || isTab ? "body1" : "h6"}
                          sx={{ fontWeight: "bold" }}
                        >
                          C/O {item.careof}
                        </Typography>
                      ) : (
                        <Typography
                          variant={Mobile || isTab ? "body1" : "h6"}
                          sx={{ fontWeight: "bold" }}
                        >
                          {item.companyname}
                        </Typography>
                      )}

                      <Typography variant="body1">{item.add1}</Typography>

                      <Typography variant="body1">{item.add2}</Typography>

                      <Typography variant="body1">{item.city}</Typography>

                      <Typography variant="body1">{item.state}</Typography>

                      <Typography variant="body1">{item.zipcode}</Typography>

                      {item.country === "Malaysia" ? null : (
                        <Stack
                          direction="row"
                          alignItems="center"
                          // justifyContent="flex-start"
                          sx={{ width: "100%", py: "5px" }}
                          spacing={1}
                        >
                          <IconEmail />
                          <Link
                            to={`mailto:${item.contactemail}`}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {item.contactemail}
                          </Link>
                        </Stack>
                      )}
                    </Stack>
                  </Fragment>
                ))}
            </Stack>
          </Stack>
        </Grid>
        <Contactform />
      </Grid>
    </Stack>
  );
}

export default Contactus;
