import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainHeading from "../resuable/MainHeading";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { getCountries, raiseEnquiry } from "../../api/Main";
import { ErrorMessage } from "@hookform/error-message";
import { useSnackbar } from "notistack";

// -----------------------------------------------  Form Components

const schema = yup.object({
  fullname: yup.string().required(" Full Name  is Required !"),
  company: yup.string().required(" Company is Required !"),
  email: yup.string().required(" Email is Required !").email("Invalid Email Formatt !"),
  country: yup.string().required(" Country  is Required !"),
  ccode: yup.string(),
  mobile: yup.string(),
  industry: yup.string().required("Industry is Required !"),
  message: yup.string(),

});

const defaultValues = {
  fullname: "",
  company: "",
  email: "",
  country: "",
  ccode: "",
  mobile: "",
  industry: "",
  message: "",
  
};

function Contactform() {
  const { enqueueSnackbar } = useSnackbar();
  const Mobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "sm")
  );

  const Tab = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));

  const [countrieslist, setcountrieslist] = useState([]);

  useEffect(() => {
    getCountries()
      .then((res) => {
        // console.log(res);
        setcountrieslist(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const onSubmit = (data) => {
    // console.log(data);

    const reqdata = {
      fullname: data.fullname,
      company: data.company,
      wemail: data.email,
      country: data.country,
      mobile: `(${data.ccode}) ${data.mobile}`,
      message: data.message,
      tc: data.tc,
      industry:data.industry,
      domain: "relevantsolutions.com.my",
      page: "relevantsolutions.com.my",
    };

    raiseEnquiry(reqdata)
      .then((res) => {
        // console.log(res);
        const status = res.data.success;
        if (status) {
          reset()
          enqueueSnackbar(res.data.message, { variant: "success" });
        } else {
          enqueueSnackbar(res.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        display: "flex",
        alignItems: "start",
        justifyContent: "space-evenly",
        flexDirection: "column",
        // border: "1px solid lightgray",
        py: "10px",
        px: Mobile || Tab ? "20px" : "40px",
        height: Mobile || Tab ? "auto" : "100%",
        // width: "100%",
        gap: "10px",
      }}
    >
      <Stack
        direction="column"
        alignItems={Mobile || Tab ? "center" : "left"}
        spacing={1.5}
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Typography
          variant={Mobile || Tab ? "h5" : "h4"}
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            textAlign: Mobile || Tab ? "center" : "left",
          }}
        >
          Send us a Message
        </Typography>

        <Box
          sx={{
            width: "100px",
            height: "5px",
            borderRadius: 2,
            bgcolor: "primary.BlueSonki",
          }}
        />
      </Stack>

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", p: "10px" }}
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack
          component={Grid}
          item
          xs={12}
          md={12}
          direction={Mobile || Tab ? "column" : "row"}
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <TextField
            label="Full Name"
            fullWidth
            autoComplete="false"
            error={errors.fullname}
            helperText={errors.fullname?.message}
            {...register("fullname")}
          />
          <TextField
            label="Company"
            fullWidth
            autoComplete="off"
            error={errors.company}
            helperText={errors.company?.message}
            {...register("company")}
          />
        </Stack>
        <Stack
          component={Grid}
          item
          xs={12}
          md={12}
          direction={Mobile || Tab ? "column" : "row"}
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <TextField
            label="Work Email"
            fullWidth
            autoComplete="off"
            error={errors.email}
            helperText={errors.email?.message}
            {...register("email")}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Autocomplete
                  sx={{ width: "100%" }}
                  value={
                    value
                      ? countrieslist.find((option) => {
                          return value == option.countryname;
                        }) ?? null
                      : null
                  }
                  getOptionLabel={(option) => {
                    return `${option.countryname}`;
                  }}
                  options={countrieslist}
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.countryname : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      error={errors.country}
                      helperText={errors.country?.message}
                      autoComplete="off"
                    />
                  )}
                />
              );
            }}
          />
        </Stack>
        <Stack
          component={Grid}
          item
          xs={12}
          md={12}
          direction={Mobile || Tab ? "column" : "row"}
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Controller
            name="ccode"
            control={control}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Autocomplete
                  sx={{ width: "100%" }}
                  value={
                    value
                      ? countrieslist.find((option) => {
                          return value == option.countrydailcode;
                        }) ?? null
                      : null
                  }
                  getOptionLabel={(option) => {
                    return `${option.countrydailcode} (${option.countrycode})`;
                  }}
                  options={countrieslist}
                  onChange={(e, newValue) => {
                    onChange(newValue ? newValue.countrydailcode : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Code "
                      error={errors.ccode}
                      helperText={errors.ccode?.message}
                    />
                  )}
                />
              );
            }}
          />
          <TextField
            label="Mobile"
            fullWidth
            autoComplete="off"
            error={errors.mobile}
            helperText={errors.mobile?.message}
            {...register("mobile")}
          />
          <TextField
            label="Niche Industry"
            fullWidth
            autoComplete="off"
            error={errors.industry}
            helperText={errors.industry?.message}
            {...register("industry")}
          />
        </Stack>
        <Stack
          component={Grid}
          item
          xs={12}
          md={12}
          direction={Mobile || Tab ? "column" : "row"}
          alignItems="center"
          spacing={1}
          sx={{ width: "100%" }}
        >
          <TextField
            label="Message"
            multiline
            rows={2}
            fullWidth
            autoComplete="off"
            error={errors.message}
            helperText={errors.message?.message}
            {...register("message")}
          />
        </Stack>
        {/* <Stack
          direction="column"
          alignItems="left"
          justifyContent="left"
          sx={{ width: "100%" }}
        >
          <FormControl component="fieldset">
            <Controller
              control={control}
              name="conditions"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row" alignItems="center">
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="I agree to the Terms and Conditions"
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="tc"
              render={({ message }) => (
                <Typography variant="body2" sx={{ color: "red" }}>
                  {message}
                </Typography>
              )}
            />
          </FormControl>
        </Stack> */}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="left"
          sx={{ width: "100%", pb: 2 }}
          spacing={1}
        >
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
}

export default Contactform;
