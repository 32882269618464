import React from "react";
import "./App.css";
import Navbar from "./components/common/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { ThemeProvider } from "@mui/material/styles";
import { CustomTheme } from "./theme/Theme";
import GlobalRoutes from "./routes/GlobalRoutes";
import CommingSoon from "./components/common/CommingSoon";
import ScrollToTop from "./components/common/ScrolltoTop";
import { SnackbarContent, SnackbarProvider } from "notistack";

function App() {
  return (
    <ThemeProvider theme={CustomTheme}>
      <SnackbarProvider maxSnack={3}>
        <ScrollToTop />
        <Navbar />
        <GlobalRoutes />
      </SnackbarProvider>

      {/* <CommingSoon /> */}
    </ThemeProvider>
  );
}

export default App;
